import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Page } from "@atoms/layout/page";
import { Base, Info, Title } from "@atoms/text";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { usePageNavigation } from "@features/utils/navigation";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoader } from "@atoms/layout/page-loader";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { CommandType } from "@features/supplier/types";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { flushGlobalEffects } from "@features/utils/hooks/use-global-effect";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import { ROUTES } from "@features/routes";
import { useProducts } from "@features/products/state/use-products";
import { FaBuildingWheat } from "react-icons/fa6";
import { useBasket } from "@features/products/state/use-basket";
import Select from "@atoms/input/input-select";
import _ from "lodash";
import { Input } from "@atoms/input/input-text";
import { InputLabel } from "@atoms/input/decoration-label";
import toast from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { ProductInfosAtom } from "@components/product/product-infos-modal";
import { RiPagesLine } from "react-icons/ri";
import { LieuStockAtom } from "@views/client/products/product/lieu-stock-modal";
import { ProductsApiClient } from "@features/products/api-client/api-client";

//Peut être plus tard à factoriser avec la commande --> En attente d'info

export const SupplierCommandOneClickPageMulti = () => {
  const { goToPreviousPage } = usePageNavigation();
  const { basketId } = useParams<{
    basketId: string;
  }>();

  const {
    loading: orderLoading,
    getModesExpedition,
    createOrder,
  } = useSuppliers();
  const { current } = useShopLocations();
  const { products: basketProducts, add, remove } = useBasket(basketId || "");
  const navigate = useNavigate();
  //const { scanOn } = useScan();
  //const [scanValue, setScanValue] = useState("");
  const { loading: productLoading, getProductSupplier } = useProducts();
  const [products, setProducts] = useState<CommandType[]>([]);
  const [panieGlobal, setPanieGlobal] = useState("");
  const [modeExpeGlobal, setModeExpeGlobal] = useState("09");
  const setProductInfosModal = useSetRecoilState(ProductInfosAtom);
  const setLieuStockModal = useSetRecoilState(LieuStockAtom);

  const [key, setKey] = useState(false);
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [fournOption, setFournOptions] = useState<
    Record<string, { value: string; label: string }[]>
  >({});

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([
        ...(await getModesExpedition()).map((el) => {
          return { label: `${el.lib} (${el.code})`, value: el.code };
        }),
      ]);
    };
    getModeExp();
  }, []);

  useControlledEffect(() => {
    const getFournInfos = async () => {
      const fournInfos: Record<string, { value: string; label: string }[]> = {};

      for (let el of basketProducts) {
        const res = await getProductSupplier(el.product.id);
        if (res && res.length > 0) {
          fournInfos[el.product.id] = _.unionBy(
            [
              {
                value: el.product.codeFournHabit,
                label: el.product.fournHabit,
              },
              ...res.map((f) => ({
                value: f.codeFourn,
                label: f.raisSocial,
              })),
            ],
            "value"
          );
        }
      }
      setFournOptions((prev) => {
        return { ...prev, ...fournInfos };
      });
      setKey(true);
    };
    setProducts(
      basketProducts.map((el) => {
        return {
          ...el.product,
          fournReturn: {
            value: el.product.codeFournHabit,
            label: el.product.fournHabit,
          },
          basket: "",
          autorisation: true,
          codeModeExpe: "09", //Par défaut
          qteOrdered: el.count,
          couvPrevis: 0,
        } as CommandType;
      })
    );
    !key && getFournInfos();
  }, []);

  if (productLoading || !modesExpedition) {
    return <PageLoader />;
  }

  // const handleSubmit = async (ean: string) => {
  //   const query = ean;
  //   setScanValue("");
  //   if (query.length > 0) {
  //     //await ProductsApiClient.referenceProduct(query);
  //     const product = (await ProductsApiClient.getProductScan(
  //       query
  //     )) as ProductTypeShort[];
  //     if (product.length > 0) {
  //       add(product[0], 1);
  //     }
  //   }
  // };

  const handleChange = async (ean: string, key: string, newValue: any) => {
    // console.log(products);
    // console.log(fournOption);
    setProducts((prev) => {
      const tempTab = prev.map((p) =>
        p.id === ean ? { ...p, [key]: newValue } : p
      );
      return tempTab.filter((el) => el.qteOrdered > 0);
    });
    if (key === "qteOrdered") {
      const basketElement = basketProducts.find((el) => el.product.id === ean);
      if (basketElement) {
        remove(basketElement?.product, 999);
        add(basketElement?.product, newValue, false);
      }
    }
    //setKey((prev) => !prev);
  };

  const allColumns: Column<CommandType>[] = [
    {
      title: "Article",
      render: (p, { responsive }) =>
        responsive ? (
          <div
            className="text-right"
            onClick={() => {
              navigate(
                ROUTES.Product.replace(/:type/, p.tooltip.modeGest)
                  .replace(/:ean/, p.id)
                  .replace(/:referGestion/, "true")
              );
            }}
          >
            <Base
              data-html={true}
              data-tooltip={getCommonProductTooltip(p, p.tooltip)}
            >
              {p.designation}
            </Base>
            <br />
            <Info>{p.id}</Info>
          </div>
        ) : (
          <div className="grow flex justify-between">
            <div
              className="flex flex-row ps-center space-x-4 cursor-pointer"
              onClick={() => {
                navigate(
                  ROUTES.Product.replace(/:type/, p.modeGest)
                    .replace(/:ean/, p.id)
                    .replace(/:referGestion/, "true")
                );
              }}
            >
              <div className="w-8 print:hidden">
                <BookRender src={p.imageURL} productType={p.modeGest} />
              </div>

              <div className="flex flex-col">
                <Base
                  className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                  data-html={true}
                  data-tooltip={getCommonProductTooltip(p, p.tooltip)}
                >
                  {p.designation}
                </Base>
                <Info>{p.id}</Info>
              </div>
            </div>
            <div className="w-full flex gap-2 justify-end item-center">
              <Button
                className="shrink-0 self-center"
                size="sm"
                onClick={async (e) => {
                  e.stopPropagation();
                  setProductInfosModal({
                    open: true,
                    ean: p.id,
                    modeGest: p.modeGest,
                  });
                }}
                theme="secondary"
                data-tooltip="Voir les informations produit"
                data-tooltip-id="my-tooltip-inline"
                icon={({ className }) => <RiPagesLine className={className} />}
              />
              <Button
                className="shrink-0 self-center"
                size="sm"
                onClick={async (e) => {
                  e.stopPropagation();
                  const res = await ProductsApiClient.getLieuStock(p.id);
                  setLieuStockModal({
                    open: true,
                    product: p,
                    lieuStockList: res,
                  });
                }}
                theme="secondary"
                data-tooltip="Stocks des lieux"
                data-tooltip-id="my-tooltip-inline"
                icon={({ className }) => (
                  <FaBuildingWheat className={className} />
                )}
              />
            </div>
          </div>
        ),
    },
    {
      title: "Exp.",
      id: "codeModeExpe",
      //orderable: true,
      noSearch: true,
      searchIds: ["codeModeExpe", "libModeExpe"],
      render: (p) => (
        <div className="w-full flex justify-center">
          <Select
            className="max-w-40"
            size="sm"
            value={p.codeModeExpe}
            onChange={(e) => {
              handleChange(p.id, "codeModeExpe", e.target.value);
            }}
          >
            {modesExpedition &&
              modesExpedition.map((el) => (
                <option value={el.value}>{el.label}</option>
              ))}
          </Select>
        </div>
      ),
    },
    {
      title: "Fournisseur",
      id: "fournHabit",
      //orderable: true,
      render: (p) => (
        <div className="w-full flex justify-center">
          <Select
            className="max-w-40"
            size="sm"
            key={`${key}`}
            value={p.codeFournHabit}
            onChange={(e) => {
              handleChange(p.id, "codeFournHabit", e.target.value);
              const f = fournOption[p.id].find(
                (el) => el.value === e.target.value
              );
              if (f) handleChange(p.id, "fournHabit", f.label);
            }}
          >
            {fournOption &&
              fournOption[p.id] &&
              fournOption[p.id].map((el: any) => (
                <option value={el.value}>{el.label}</option>
              ))}
          </Select>
        </div>
      ),
    },
    {
      title: "Panier",
      id: "basket",
      //orderable: true,
      render: (p) => (
        <div className="w-full flex justify-center">
          <Input
            key={`${key}`}
            className="max-w-28"
            size="sm"
            value={p.basket}
            onChange={(e) => {
              handleChange(p.id, "basket", e.target.value);
            }}
          />
        </div>
      ),
    },
    {
      title: `Prix`,
      id: "prixVente",
      type: "price",
      //orderable: true,
      render: (p) =>
        `${p.prixVente.toFixed(current?.devisNbDecim)}
          ${p.devisSymb || "€"}`,
    },
    {
      title: "Quant.",
      id: "qteOrdered",
      //orderable: true,
      render: (p) => (
        <div className="w-full flex flex-col justify-center items-center gap-1">
          <div style={{ maxWidth: 128, minWidth: 96 }}>
            <InputCounter
              key={`${key}`}
              size={"sm"}
              value={p.qteOrdered}
              deleteIconOnZero={true}
              confirmOnZero={true}
              onDelete={() => {
                setProducts((prev) => {
                  const index = prev.findIndex((el) => el.id === p.id);
                  return prev.filter((_, i) => i !== index);
                });
                remove(p);
              }}
              min={1}
              max={999}
              onChange={(value) => {
                handleChange(p.id, "qteOrdered", value);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Page loading={orderLoading}>
      <div className="w-full flex flex-col md:flex-row gap-16">
        <Title className="w-fit">Préparation de commande fournisseur</Title>
        {/* <div className="w-full flex max-w-xl">
          <div className="ml-auto flex">
            <InputWithSuggestions
              inputMode={scanOn ? "none" : undefined}
              ean={true}
              value={scanValue}
              options={[]}
              inputClassName="to-focus !rounded-l-lg !rounded-r-none"
              autoFocus={true}
              placeholder="Ajouter un article"
              onChange={(e) => {
                setScanValue(e.target.value);
              }}
            />

            <Button
              className="shrink-0 !rounded-r-lg !rounded-l-none"
              shortcut={["enter"]}
              icon={(p) => <PlusIcon {...p} />}
              onClick={async () => await handleSubmit(scanValue)}
            />
          </div>
        </div> */}
      </div>
      {modesExpedition && (
        <div className="flex justify-end gap-8 mt-4">
          <InputLabel
            label="Mode d'expédition global"
            input={
              <Select
                className="max-w-40"
                size="md"
                value={modeExpeGlobal}
                onChange={(e) => {
                  setProducts(
                    products.map((p) => {
                      return p.codeModeExpe === modeExpeGlobal
                        ? { ...p, codeModeExpe: e.target.value }
                        : p;
                    })
                  );
                  setModeExpeGlobal(e.target.value);
                }}
              >
                {modesExpedition &&
                  modesExpedition.map((el) => (
                    <option value={el.value}>{el.label}</option>
                  ))}
              </Select>
            }
          />
          <InputLabel
            label="Panier global"
            input={
              <Input
                className="max-w-40"
                size="md"
                value={panieGlobal}
                onChange={(e) => {
                  setProducts(
                    products.map((p) => {
                      return p.basket === panieGlobal
                        ? { ...p, basket: e.target.value }
                        : p;
                    })
                  );
                  setPanieGlobal(e.target.value);
                }}
              />
            }
          />
        </div>
      )}
      <div key={`${key}`} className="grow flex flex-col gap-2 xl:my-2">
        <Table columns={allColumns} data={products} />
        <div className="w-full flex justify-evenly mb-2 xl:mt-2">
          <Button
            onClick={(e) => {
              flushGlobalEffects();
              goToPreviousPage("/products");
            }}
            theme="danger"
          >
            Annuler
          </Button>
          <Button
            disabled={orderLoading}
            shortcut={["enter"]}
            icon={(p) => <CheckCircleIcon {...p} />}
            onClick={async () => {
              let errors = [];
              for (let product of products) {
                const res = await createOrder(product, current?.codeLieu || "");
                if (!res) errors.push(product);
              }
              setProducts(errors);
              if (errors.length === 0) {
                toast.success(
                  "Toutes les préparation de commande fournisseur ont été crées."
                );
                goToPreviousPage("/products");
              } else
                toast.error(
                  "Erreur lors de la préparation en commande de certains articles"
                );
            }}
          >
            Valider
          </Button>
        </div>
      </div>
    </Page>
  );
};
