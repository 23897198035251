import { Page } from "@atoms/layout/page";
import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  ChartOptions,
  Title as ChartTitle,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Chart, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Frame } from "@atoms/layout/frame";
import { useStatistics } from "@features/dashboard/state/use-statistics";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { PageLoader } from "@atoms/layout/page-loader";
import { useEffect, useState } from "react";
import { SalesRevenue } from "@features/dashboard/type";
import { InfoBold, Title } from "@atoms/text";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/decoration-label";
import { diffInday, toDateISOFr } from "@features/utils/format/dates";
import { Button } from "@atoms/button/button";
import { FiRefreshCcw } from "react-icons/fi";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  ChartTitle,
  Tooltip,
  Legend
);

export const DashboardPage = () => {
  const { loading, getSalesRevenue } = useStatistics();
  const { current } = useShopLocations();
  const [data, setData] = useState<SalesRevenue[]>([]);
  const [pieDataArray, setPieDataArray] = useState<SalesRevenue[]>([]);
  const [dateChanged, setDateChanged] = useState(false);
  const [dateTo, setDateTo] = useState<Date | null>(new Date());
  const [dateFrom, setDateFrom] = useState<Date | null>(
    dateTo ? new Date(new Date().setDate(new Date().getDate() - 3)) : null
  );
  const [nbDiffJour, setNbDiffJour] = useState(3);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const getStats = async (codeCanalVente: string, type: string) => {
    // const today = new Date();
    // const lastWeek = new Date();
    // lastWeek.setDate(today.getDate() - 7);
    // setDateFrom(lastWeek);
    // setDateTo(today);
    if (dateTo || dateFrom) {
      const res = await getSalesRevenue(
        current?.codeLieu || "",
        codeCanalVente,
        type,
        dateFrom ? dateFrom : dateTo,
        dateTo ? dateTo : dateFrom
      );
      return res;
    }
  };

  useControlledEffect(() => {
    getStats("CPT", "GLOB").then((res) => (res ? setData(res) : setData([])));
    getStats("", "CUM").then((res) =>
      res ? setPieDataArray(res) : setPieDataArray([])
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize); // Écoute le redimensionnement

    return () => window.removeEventListener("resize", handleResize); // Nettoyage
  }, []);

  if (loading) return <PageLoader />;

  const datesUniques = Array.from(new Set(data.map((d) => d.dateVente))); // Récupère toutes les dates uniques

  const chartData = {
    labels: datesUniques.map((d) => toDateISOFr(d.toString())),
    datasets: [
      {
        label: "CPT",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "CPT"
          );
          return item ? item.caNetTtc : 0;
        }),
        backgroundColor: "rgba(221, 147, 10, 0.6)",
        //stack: "CPT",
      },
      {
        label: "INT",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "INT"
          );
          return item ? item.caNetTtc : 0;
        }),
        backgroundColor: "rgba(99, 167, 255, 0.6)",
        //stack: "INT",
      },

      {
        label: " VAT",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "VAT"
          );
          return item ? item.caNetTtc || 0 : 0;
        }),
        backgroundColor: "rgba(238, 22, 6, 0.3)",
        //stack: "VAT",
        tvaGroup: true,
      },
      {
        label: "Total",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const items = data.filter((d) => d.dateVente === date);
          const total = items.reduce((acc, el) => acc + el.caNetTtc, 0);
          return total;
        }),
        backgroundColor: "rgba(0, 196, 59, 0.3)",
        //stack: "Total",
        tvaGroup: true,
      },
    ],
  };

  const chartDataQte = {
    labels: datesUniques.map((d) => toDateISOFr(d.toString())),
    datasets: [
      {
        label: "CPT",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "CPT"
          );
          return item ? item.qte : 0;
        }),
        backgroundColor: "rgba(221, 147, 10, 0.6)",
        //stack: "CPT",
      },
      {
        label: "INT",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "INT"
          );
          return item ? item.qte : 0;
        }),
        backgroundColor: "rgba(99, 167, 255, 0.6)",
        //stack: "INT",
      },
      {
        label: "Total",
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const items = data.filter((d) => d.dateVente === date);
          const total = items.reduce((acc, el) => acc + el.qte, 0);
          return total;
        }),
        backgroundColor: "rgba(0, 196, 59, 0.3)",
        //stack: "Total",
        tvaGroup: true,
      },
    ],
  };

  const barOptions: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {},
      },
      datalabels: {
        display: nbDiffJour > 3 ? false : true,
        color: "black",
        anchor: "center",
        align: "center",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => {
          if (value < 100) return "";
          return `${value.toFixed(0)}${current?.devisSymb || "€"}`;
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw as number; // Récupère la valeur brute
            return `${value.toFixed(current?.devisNbDecim || 2)} ${
              current?.devisSymb || "€"
            }`;
          },
          title: (tooltipItems) => {
            const date = tooltipItems[0].label; // Récupère la date
            return `Date : ${date}`;
          },
        },
      },

      title: {
        display: true,
        text: "CA Net TTC des différents canaux de vente",
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        stacked: isMobile,
        title: {
          display: true,
          text: "Date", // Libellé pour l'axe X
          font: {
            size: 12, // Taille du texte
            weight: "bold", // Mettre en gras
          },
        },
      },
      y: {
        stacked: isMobile,
        title: {
          display: true,
          text: `Montant ${current?.devisSymb || "€"}`, // Libellé pour l'axe Y
          font: {
            size: 12,
            weight: "bold",
          },
        },
        ticks: {
          callback: (value) => `${value} €`, // Ajoute "€" aux valeurs de l'axe Y
        },
      },
    },
  };

  // For CPT only
  const cptChartData = {
    labels: datesUniques.map((d) => toDateISOFr(d.toString())),
    datasets: [
      {
        label: "CA Net HT",
        type: "bar" as const,
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "CPT"
          );
          return item ? item.caNetHt : 0;
        }),
        backgroundColor: "rgba(3, 114, 22, 0.6)",
      },
      {
        label: "CA Brut TTC",
        type: "bar" as const,
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "CPT"
          );
          return item ? item.caBrutTtc : 0;
        }),
        backgroundColor: "rgba(171, 192, 75, 0.6)",
      },
      {
        label: "Remise",
        type: "bar" as const,
        borderWidth: 2,
        borderRadius: 5,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "CPT"
          );
          return item ? item.totalRemise : 0;
        }),
        backgroundColor: "rgba(156, 7, 131, 0.6)",
      },
      // {
      //   label: "Ticket moyen",
      //   type: "line" as const,
      //   borderWidth: 2,
      //   fill: true,
      //   data: datesUniques.map((date) => {
      //     const item = data.find(
      //       (d) => d.dateVente === date && d.codeCanalVente === "CPT"
      //     );
      //     return item ? item.moyTicket : 0;
      //   }),
      //   backgroundColor: "rgba(236, 233, 11, 0.6)",
      // },
    ],
  };

  const cptPanetMoyChart = {
    labels: datesUniques.map((d) => toDateISOFr(d.toString())),
    datasets: [
      {
        label: "Ticket moyen",
        type: "line" as const,
        borderWidth: 3,
        fill: true,
        data: datesUniques.map((date) => {
          const item = data.find(
            (d) => d.dateVente === date && d.codeCanalVente === "CPT"
          );
          return item ? item.moyTicket : 0;
        }),
        pointRadius: 5, // Taille normale des points
        pointHoverRadius: 8, // Taille des points au survol
        pointBorderWidth: 2, // Bordure des points
        pointHoverBorderWidth: 2, // Bordure au survol

        borderColor: "rgba(0, 206, 172, 0.42)", // Couleur de la ligne (rouge ici)
        //backgroundColor: "rgba(255, 99, 132, 0.2)", // Couleur sous la ligne avec opacité
        pointBackgroundColor: "rgba(1, 88, 187, 0.81)", // Couleur des points
        pointBorderColor: "rgba(0, 0, 0, 0.69)", // Bordure des points
        pointHoverBackgroundColor: "rgba(0, 206, 172, 0.82)", // Couleur du point au survol
        //pointHoverBorderColor: "rgb(243, 243, 243)", // Bordure du point au survol
      },
    ],
  };

  const chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {},
      },
      datalabels: {
        display: nbDiffJour > 3 ? false : true,
        color: "black",
        anchor: "center",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => `${value.toFixed(0)}${current?.devisSymb || "€"}`, // Format des valeurs
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw as number; // Récupère la valeur brute
            return `${value.toFixed(current?.devisNbDecim || 2)} ${
              current?.devisSymb || "€"
            }`;
          },
          title: (tooltipItems) => {
            const date = tooltipItems[0].label; // Récupère la date
            return `Date : ${date}`;
          },
        },
      },
      title: {
        display: true,
        text: "Détail du canal comptant",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date", // Libellé pour l'axe X
          font: {
            size: 12, // Taille du texte
            weight: "bold", // Mettre en gras
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `Montant ${current?.devisSymb || "€"}`, // Libellé pour l'axe Y
          font: {
            size: 12,
            weight: "bold",
          },
        },
        ticks: {
          callback: (value) => `${value} €`, // Ajoute "€" aux valeurs de l'axe Y
        },
      },
    },
  };

  const chartOptionsQte: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {},
      },
      datalabels: {
        display: nbDiffJour > 3 ? false : true,
        color: "black",
        anchor: "center",
        align: "top",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => value.toFixed(0), // Format des valeurs
      },
      title: {
        display: true,
        text: "Quantité d'articles vendus par canal de vente (CPT/INT)",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date", // Libellé pour l'axe X
          font: {
            size: 12, // Taille du texte
            weight: "bold", // Mettre en gras
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `Quantité vendue`, // Libellé pour l'axe Y
          font: {
            size: 12,
            weight: "bold",
          },
        },
      },
    },
  };

  const chartOptionsPanieMoy: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {},
      },
      datalabels: {
        display: nbDiffJour > 3 ? false : true,
        color: "black",
        anchor: "center",
        align: "bottom",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value) => `${value.toFixed(0)}${current?.devisSymb || "€"}`, // Format des valeurs
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw as number; // Récupère la valeur brute
            return `${value.toFixed(current?.devisNbDecim || 2)} ${
              current?.devisSymb || "€"
            }`;
          },
          title: (tooltipItems) => {
            const date = tooltipItems[0].label; // Récupère la date
            return `Date : ${date}`;
          },
        },
      },
      title: {
        display: true,
        text: "Canal comptant: ticket moyen",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date", // Libellé pour l'axe X
          font: {
            size: 12, // Taille du texte
            weight: "bold", // Mettre en gras
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `Montant ${current?.devisSymb || "€"}`, // Libellé pour l'axe Y
          font: {
            size: 12,
            weight: "bold",
          },
        },
        ticks: {
          callback: (value) => `${value} €`, // Ajoute "€" aux valeurs de l'axe Y
        },
      },
    },
  };

  const pieOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Part du CA Net TTC des différents canaux de vente",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((acc, val) => acc + val, 0);
            const value = dataset.data[tooltipItem.dataIndex];
            const percentage = ((value * 100) / total).toFixed(2);

            return `${tooltipItem.label}: ${value.toFixed(
              current?.devisNbDecim || 2
            )} ${current?.devisSymb || "€"} - (${percentage}%)`;
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const chart = context.chart;
          const legendItems = chart.legend?.legendItems;

          const total = pieDataArray.reduce(
            (acc, produit) => acc + produit.caNetTtc,
            0
          );
          const percentage = (value * 100) / total;

          if (legendItems) {
            if (legendItems.filter((el) => el.hidden).length > 0)
              return percentage.toFixed(2) + "%";
          }

          return percentage < 3 ? "" : percentage.toFixed(2) + "%";
        },
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const legendItems = chart.legend?.legendItems;

          const value = pieDataArray[context.dataIndex].caNetTtc;
          const total = pieDataArray.reduce(
            (acc, val) => acc + val.caNetTtc,
            0
          );
          const percentage = (value * 100) / total;

          if (legendItems) {
            if (legendItems.filter((el: any) => el.hidden).length > 0)
              return "rgba(0, 128, 255, 0.33)";
          }
          return percentage < 5
            ? "rgba(0, 0, 0, 0)"
            : "rgba(0, 128, 255, 0.33)";
        },
        color: "black", // Couleur du texte
        font: {
          weight: "bold", // Met#007ffftre en gras
        },
        //backgroundColor: "white", // Fond du label
        align: "center", // Aligner le texte en dehors du graphique

        borderRadius: 6,
        textAlign: "center",
        clamp: true,
        offset: 32,
        anchor: (context) => {
          const value = pieDataArray[context.dataIndex].caNetTtc;
          const total = pieDataArray.reduce(
            (acc, val) => acc + val.caNetTtc,
            0
          );
          const percentage = (value * 100) / total;

          return percentage < 5 ? "end" : "center"; // Labels extérieurs si < 5%
        },
      },
    },
  };

  const pieData = {
    labels: pieDataArray.map((el) => el.codeCanalVente),
    datasets: [
      {
        label: "CA Net HT",
        data: pieDataArray.map((el) => el.caNetTtc),
        borderWidth: 1,
        backgroundColor: [
          "rgba(221, 147, 10, 0.6)",
          "rgba(99, 167, 255, 0.6)",
          "rgba(238, 22, 6, 0.3)",
        ],
        hoverBorderWidth: 8,
        offset: 30,
        borderRadius: 5,
      },
    ],
  };

  return (
    <Page>
      <Title className="mb-4">Tableau de bord</Title>
      <div className="w-full flex gap-8 mb-4">
        <InputLabel
          label="Début"
          input={
            <InputDate
              size="sm"
              value={dateFrom}
              onChange={(newDate) => {
                setDateChanged(true);
                setDateFrom(newDate);
              }}
              onBlur={() => console.log("BLUUUREED")}
            />
          }
        />
        <InputLabel
          label="Fin"
          input={
            <InputDate
              size="sm"
              value={dateTo}
              onChange={(newDate) => {
                setDateChanged(true);
                setDateTo(newDate);
              }}
            />
          }
        />
        {dateChanged && (
          <Button
            size="sm"
            className="self-end"
            disabled={loading || !dateFrom || !dateTo}
            shortcut={["enter"]}
            onClick={async () => {
              getStats("CPT", "GLOB").then((res) =>
                res ? setData(res) : setData([])
              );
              getStats("", "CUM").then((res) =>
                res ? setPieDataArray(res) : setPieDataArray([])
              );
              setDateChanged(false);
              if (dateFrom && dateTo)
                setNbDiffJour(diffInday(dateFrom, dateTo));
            }}
            icon={(p) => <FiRefreshCcw {...p} />}
          >
            Rafraichir
          </Button>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <Frame className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 px-6">
          <div className="max-h-[300px] md:col-span-2 flex justify-center">
            <Doughnut data={pieData} options={pieOptions} />
          </div>
          <div className="w-full h-full flex flex-col gap-4 justify-center px-4 bg-gray-100 dark:bg-slate-700 rounded-lg shadow-md">
            <div className="flex gap-2">
              <span
                style={{ backgroundColor: "rgba(221, 147, 10, 0.6)" }}
                className="w-4 h-1 self-center"
              />
              <InfoBold>
                {`
                CPT: ${
                  pieDataArray.find((el) => el.codeCanalVente === "CPT")
                    ? pieDataArray
                        .find((el) => el.codeCanalVente === "CPT")
                        ?.caNetTtc.toFixed(current?.devisNbDecim || 2)
                    : "-"
                }
                   ${current?.devisSymb || "€"} `}
              </InfoBold>
            </div>
            <div className="flex gap-2">
              <span
                style={{ backgroundColor: "rgba(0, 128, 255, 0.33)" }}
                className="w-4 h-1 self-center"
              />
              <InfoBold>
                {`
                INT: ${
                  pieDataArray.find((el) => el.codeCanalVente === "INT")
                    ? pieDataArray
                        .find((el) => el.codeCanalVente === "INT")
                        ?.caNetTtc.toFixed(current?.devisNbDecim || 2)
                    : "-"
                }
                   ${current?.devisSymb || "€"} `}
              </InfoBold>
            </div>
            <div className="flex gap-2">
              <span
                style={{ backgroundColor: "rgba(238, 22, 6, 0.3)" }}
                className="w-4 h-1 self-center"
              />
              <InfoBold>
                {`
                VAT: ${
                  pieDataArray.find((el) => el.codeCanalVente === "VAT")
                    ? pieDataArray
                        .find((el) => el.codeCanalVente === "VAT")
                        ?.caNetTtc.toFixed(current?.devisNbDecim || 2)
                    : "-"
                }
                   ${current?.devisSymb || "€"} `}
              </InfoBold>
            </div>
            <div className="flex gap-2">
              <span
                style={{ backgroundColor: "rgba(0, 196, 59, 0.3)" }}
                className="w-4 h-1 self-center"
              />
              {pieDataArray.length > 0 && (
                <InfoBold>
                  {`
                Total: ${pieDataArray
                  .reduce((acc, el) => acc + el.caNetTtc, 0)
                  .toFixed(current?.devisNbDecim || 2)} ${
                    current?.devisSymb || "€"
                  } `}
                </InfoBold>
              )}
            </div>
          </div>
        </Frame>
        <Frame className="w-full">
          <Bar
            className="min-h-[400px] md:min-h-[300px] overflow-x-scroll md:overflow-x-hidden "
            data={chartData}
            options={barOptions}
          />
        </Frame>
        <Frame className="w-full">
          <Chart
            className="min-h-[400px] md:min-h-[300px] overflow-x-scroll md:overflow-x-hidden "
            type="bar"
            data={cptChartData}
            options={chartOptions}
          />
        </Frame>
        <Frame className="w-full">
          <Chart
            className="self-center min-h-[400px] md:min-h-[300px] overflow-x-scroll md:overflow-x-hidden "
            type="bar"
            data={cptPanetMoyChart}
            options={chartOptionsPanieMoy}
          />
        </Frame>
        <Frame className="w-full">
          <Chart
            className="self-center min-h-[400px] md:min-h-[300px] overflow-x-scroll md:overflow-x-hidden "
            type="bar"
            data={chartDataQte}
            options={chartOptionsQte}
          />
        </Frame>
      </div>
    </Page>
  );
};
