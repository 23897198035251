import { Frame } from "@atoms/layout/frame";
import {
  Base,
  BaseBold,
  Info,
  InfoSmall,
  InfoSmallBold,
  Title,
} from "@atoms/text";
import { Button } from "@atoms/button/button";
import { ROUTES } from "@features/routes";
import { Form } from "@molecules/form";
import { useNavigate, useParams } from "react-router-dom";
import ReceptionProductCard, {
  ReceptionProductActions,
} from "@components/reception/reception-cards";
import { InputWithSuggestions } from "@atoms/input/input-with-suggestion";
import {
  ArrowNarrowLeftIcon,
  CheckCircleIcon,
  PaperClipIcon,
  PlusIcon,
  PrinterIcon,
  RefreshIcon,
  TagIcon,
  ViewListIcon,
  XIcon,
} from "@heroicons/react/outline";
import Select from "@atoms/input/input-select";
import { useEffect, useState } from "react";
import { receptionInfoModalFields } from "../receptions-modals/reception-info-modal";
import { useReception } from "@features/reception/state/use-reception";
import { useTranslation } from "react-i18next";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { BookRender } from "@atoms/book";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { ReceptionLine, ReceptionUnit } from "@features/reception/types";
import { useScan } from "@atoms/input-scan/use-scan";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { toast } from "react-hot-toast";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AffectationCmdClientModalAtom } from "../receptions-modals/affection-commande-client-modal";
import Accordion from "@atoms/accordion";
import { CommandFournModalAtom } from "../receptions-modals/commande-fournisseur-modal";
import {
  LockItemReception,
  PreviousScannedProduct,
  SelectedItemReception,
  TabKey,
} from "@features/reception/state/store";
import { Page } from "@atoms/layout/page";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { ReceptionApiClient } from "@features/reception/api-client/api-client";
import { Input } from "@atoms/input/input-text";
import { focusInput } from "@features/utils/divers";
import { InputCounter } from "@atoms/input/input-counter";
import { useProducts } from "@features/products/state/use-products";
import { FaArrowRightArrowLeft, FaArrowsRotate } from "react-icons/fa6";
import { MissingArticlesModalAtom } from "../receptions-modals/missing-articles-modal";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { ButtonConfirm } from "@atoms/button/confirm";
import { UnknownArticlesModalAtom } from "../receptions-modals/unknown-articles-modal";
import { FaExclamationTriangle } from "react-icons/fa";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { CommonPagination } from "@features/types";
import { PrintLabelsModal } from "../receptions-modals/print-labels-modal";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import { EclatKitModalAtom } from "../receptions-modals/eclat-kit-modal";
import { ProductsApiClient } from "@features/products/api-client/api-client";

export const OngoingReceptionPage = () => {
  const { clotStep } = useParams<{ clotStep: string }>();
  const [clotureStep, setClotureStep] = useState(clotStep === "1");
  const [finishedStep, setFinishedStep] = useState(false);
  const {
    selectedReception,
    getReceipt,
    deleteReceipt,
    changeSelectedReception,
    loading,
    receiptLines,
    getLines,
    setReceiptLines,
    rappatrierLines,
    closeReceipt,
    receiptScannedProduct,
    getReceiptDocument,
    getRelatedOrders,
    updateLine,
    getMissingArticles,
    updateAllPrices,
    updateFdp,
    printLabels,
  } = useReception();
  const { current, functionsAuthorization } = useShopLocations();
  const [selectedItem, setSelectedItem] = useRecoilState(SelectedItemReception);
  const [lock, setLock] = useRecoilState(LockItemReception);
  const [inputValue, setInputValue] = useState("");
  const [freshRedirect, setFreshRedirect] = useState(true);
  const [tab, setTab] = useState<any>({});
  const [initialTva, setInitialTva] = useState({
    codeTaxe: "",
    fraisPortHt: -1,
    fraisPortTtc: -1,
  });
  const setCommandFournModal = useSetRecoilState(CommandFournModalAtom);
  const printLabelModal = useRecoilValue(PrintLabelsModal);
  const setPdfModal = useSetRecoilState(PdfModal);
  const { scanOn } = useScan();
  const { t } = useTranslation();
  const { getSuggestions } = useProducts();
  const navigate = useNavigate();
  const [previousScannedProduct, setPreviousScannedProduct] = useRecoilState(
    PreviousScannedProduct
  );
  const setConfirmModal = useSetRecoilState(CommonConfirmModalAtom);
  const [affectationModal, setAffectationModal] = useRecoilState(
    AffectationCmdClientModalAtom
  );
  const setEclatKitModal = useSetRecoilState(EclatKitModalAtom);
  const setMissingArticlesModal = useSetRecoilState(MissingArticlesModalAtom);
  const setUnknownArticlesModal = useSetRecoilState(UnknownArticlesModalAtom);

  const [tabKey, setTabKey] = useRecoilState(TabKey);
  const [updateMode, setUpdateMode] = useState(false);

  const [tvaList, setTvaList] = useState<any>([]);

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "reception-ongoing-grid-mode",
    {
      activated: false,
    }
  );

  useEffect(() => {
    if (selectedItem) setTab({ selectedItem: true });
    else {
      setUpdateMode(false);
      setTimeout(() => {
        focusInput(".to-focus");
      }, 200);
    }
  }, [selectedItem]);

  useControlledEffect(() => {
    if (freshRedirect) return;

    getLines(
      selectedReception,
      {
        orderBy: "dateCreat",
        orderDir: "DESC",
        pageNumber: 0,
        pageSize: 20,
      },
      true,
      selectedItem ? selectedItem.ean13 : ""
    ).then((res) =>
      setReceiptLines({
        pagination: {
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pageCount: res.pageCount,
          totalRows: res.totalRows,
          rowsReturned: res.rowsReturned,
        },
        items: res.items,
      })
    );
  }, [selectedItem?.ean13]);

  useControlledEffect(() => {
    setTimeout(() => {
      setFreshRedirect(false);
    }, 800);

    setTimeout(() => {
      focusInput(
        selectedReception.typeRecep === 1
          ? "#product-counter input"
          : "#etiq-counter"
      );
    }, 250);

    // return () => clearTimeout(timer);
  }, []);

  useControlledEffect(() => {
    const updateRecep = async () => {
      const resTva = await CommonApiClient.getTaxes();
      setTvaList(
        resTva.map((el) => {
          return { label: el.taux, value: el.code };
        })
      );

      changeSelectedReception({
        ...(await getReceipt(selectedReception)),
        codeTaxe: resTva.find((t) => t.port === true)!.code || "",
      });

      setInitialTva({
        codeTaxe: selectedReception.codeTaxe,
        fraisPortHt: selectedReception.fraisPortHt,
        fraisPortTtc: selectedReception.fraisPortTtc,
      });
    };
    updateRecep();
  }, [clotureStep]);

  useControlledEffect(() => {
    document.title =
      "Inférence - " +
      `Recep. ${selectedReception.codeFourn} - ${selectedReception.numRecep}`;
  }, [selectedReception]);

  useControlledEffect(() => {
    if (previousScannedProduct) {
      setSelectedItem({
        ...previousScannedProduct,
        nbrEtiq:
          previousScannedProduct.nbrEtiq > 0
            ? previousScannedProduct.qteRecu
            : 0,
      });
      setTab({ selectedItem: true });
      setPreviousScannedProduct(null);
    }

    return async () => {
      setReceiptLines({
        pagination: {
          pageNumber: 0,
          pageSize: 0,
          pageCount: 0,
          totalRows: 0,
          rowsReturned: 0,
        },
        items: [],
      });
      setUpdateMode(false);
      setSelectedItem(null);
      setLock(false);
      await ReceptionApiClient.processing(selectedReception, false);
    };
  }, []);

  useEffect(() => {
    const handleCloseReceipt = async (event: Event) => {
      const res = await closeReceipt();
      if (res) {
        setFinishedStep(true);
        toast.success("Réception clôturée avec succès");
      }
    };

    window.addEventListener("toCloseReceipt", handleCloseReceipt);

    return () => {
      window.removeEventListener("toCloseReceipt", handleCloseReceipt);
    };
  }, [closeReceipt]);

  const handleSubmit = async (ean: string) => {
    if (ean.length >= 8) {
      const res = await receiptScannedProduct(ean);
      if (res) {
        if (res.ean13 !== selectedItem?.ean13) {
          setLock(true);
        }

        if (selectedItem && (res.ean13 !== selectedItem.ean13 || updateMode)) {
          const orders = await getRelatedOrders(
            selectedReception,
            selectedItem.ean13,
            res.linePk,
            selectedItem.qteRecu
          );
          setUpdateMode(false);
          setInputValue("");
          if (orders.length > 1 && !updateMode) {
            setAffectationModal({
              open: true,
              receipt: selectedReception,
              item: selectedItem,
              orders: orders,
              queingItem: { ...res, qteRecu: 1 },
              autoPrint: !updateMode,
            });
            return;
          } else {
            const relatedOrders = await getRelatedOrders(
              selectedReception,
              res.ean13,
              res.linePk,
              res.qteRecu
            );
            let forUpdate: ReceptionLine;
            setSelectedItem((previous) => {
              if (previous)
                forUpdate = {
                  ...previous,
                  qteRecu: previous.qteRecu,
                  nbrEtiq: previous.nbrEtiq,
                  autoLabel: selectedReception.etiqCdeCli !== 2 && !updateMode,
                };

              return {
                ...res,
                qteRecu:
                  selectedItem &&
                  res.ean13 === selectedItem.ean13 &&
                  !updateMode
                    ? selectedItem.qteRecu + 1
                    : res.qteRecu || 1,
                nbrEtiq:
                  res.nbrEtiq > 0 &&
                  selectedItem &&
                  res.ean13 === selectedItem.ean13 &&
                  !updateMode
                    ? selectedItem.qteRecu + 1
                    : res.qteRecu || 1,
                rayonObj: { label: res.libRayon, value: res.codeRayon },
                fabObj: { label: res.editFab, value: res.codeEditFab },
                forceRelatedOrders: relatedOrders.length > 1,
                autoLabel: selectedReception.etiqCdeCli !== 2 && !updateMode,
                tooltip: res.tooltip,
              };
            });
            await updateLine(forUpdate!, [
              {
                codeCanalVente: "",
                numCdeCli: "",
                dateCde: null,
                nomClient: "",
                telCli: "",
                mailCli: "",
                qteCde: 0,
                qteRest: 0,
                qteProposee: forUpdate!.qteRecu,
                nbrEtiq: forUpdate!.nbrEtiq,
                linePk: "",
              },
            ]);

            const updatedLines = await getLines(
              selectedReception,
              {
                orderBy: "dateCreat",
                orderDir: "DESC",
                pageNumber: 0,
                pageSize: 20,
              },
              true,
              res.ean13
            );
            setReceiptLines({
              pagination: {
                pageNumber: updatedLines.pageNumber,
                pageSize: updatedLines.pageSize,
                pageCount: updatedLines.pageCount,
                totalRows: updatedLines.totalRows,
                rowsReturned: updatedLines.rowsReturned,
              },
              items: updatedLines.items,
            });
            return;
          }
        }
        const relatedOrders = await getRelatedOrders(
          selectedReception,
          res.ean13,
          res.linePk,
          res.qteRecu
        );
        setSelectedItem((previous) => {
          return {
            ...res,
            qteRecu: previous?.qteRecu ? previous?.qteRecu + 1 : res.qteRecu,
            nbrEtiq: previous?.qteRecu ? previous?.qteRecu + 1 : res.qteRecu,

            rayonObj: { label: res.libRayon, value: res.codeRayon },
            fabObj: { label: res.editFab, value: res.codeEditFab },
            forceRelatedOrders: relatedOrders.length > 1,
            tooltip: res.tooltip,
          };
        });

        setTimeout(() => {
          focusInput(
            selectedReception.typeRecep === 1
              ? "#product-counter input"
              : "#etiq-counter"
          );
        }, 200);
      } else {
      }
      setInputValue("");
    }
  };

  const handleValidateLigne = async () => {
    if (selectedItem) {
      const activElement = document.activeElement;
      if (activElement) (activElement as HTMLInputElement).blur();
      const pIndex = receiptLines.items.findIndex(
        (el) => el.linePk === selectedItem.linePk
      );
      const orders = await getRelatedOrders(
        selectedReception,
        selectedItem.ean13,
        selectedItem.linePk,
        selectedItem.qteRecu
      );

      // const res = await updateLine(
      //   selectedItem,
      //   orders
      // );
      // const lines = await getLines(selectedReception, {
      //   orderBy: "",
      //   orderDir: "DESC",
      //   pageNumber: 1,
      //   pageSize: 80,
      // });
      if (
        (pIndex === -1 ||
          receiptLines.items[pIndex].qteRecu !== selectedItem.qteRecu) &&
        orders.length > 1 &&
        selectedReception.recepAffecCdeCli !== "0"
      ) {
        setAffectationModal({
          open: true,
          receipt: selectedReception,
          item: selectedItem,
          orders: orders,
          autoPrint: !updateMode,
        });
      } else {
        await updateLine(
          {
            ...selectedItem,
            autoLabel: selectedReception.etiqCdeCli !== 2 && !updateMode,
          },
          updateMode
            ? orders
            : [
                {
                  codeCanalVente: "",
                  numCdeCli: "",
                  dateCde: null,
                  nomClient: "",
                  telCli: "",
                  mailCli: "",
                  qteCde: 0,
                  qteRest: 0,
                  qteProposee: selectedItem.qteRecu,
                  nbrEtiq: selectedItem.nbrEtiq,
                  linePk: "",
                },
              ],
          true
        );

        // const updatedLines = await getLines(
        //   selectedReception,
        //   {
        //     orderBy: "dateCreat",
        //     orderDir: "DESC",
        //     pageNumber: 0,
        //     pageSize: 20,
        //   },
        //   true
        // );
        // if (selectedReception.etiqCdeCli !== 2 && !updateMode)
        //   await ProductsApiClient.productLabel([
        //     {
        //       ean13: selectedItem.ean13,
        //       nbrEtiq: selectedItem.nbrEtiq,
        //     },
        //   ]);
        setSelectedItem(null);
        // setReceiptLines({
        //   pagination: {
        //     pageNumber: updatedLines.pageNumber,
        //     pageSize: updatedLines.pageSize,
        //     pageCount: updatedLines.pageCount,
        //     totalRows: updatedLines.totalRows,
        //     rowsReturned: updatedLines.rowsReturned,
        //   },
        //   items: updatedLines.items,
        // });
        setTabKey((previous) => {
          return {
            count: previous.count + 1,
            refresh: true,
          };
        });
        setSelectedItem(null);
        setLock(false);

        // const lines = await getLines(
        //   selectedReception,
        //   {
        //     orderBy: "dateCreat",
        //     orderDir: "DESC",
        //     pageNumber: receiptLines.pagination.pageNumber,
        //     pageSize: receiptLines.pagination.pageSize,
        //   },
        //   true
        // );
        // setReceiptLines({
        //   pagination: {
        //     pageNumber: lines.pageNumber,
        //     pageSize: lines.pageSize,
        //     pageCount: lines.pageCount,
        //     totalRows: lines.totalRows,
        //     rowsReturned:
        //       lines.rowsReturned + receiptLines.pagination.rowsReturned,
        //   },
        //   items: lines.items,
        // });
        setSelectedItem(null);
        const updatedReception = await getReceipt(selectedReception);
        changeSelectedReception(updatedReception);
        toast.success(
          updateMode
            ? "Ligne modifiée avec succès."
            : "Ligne ajoutée avec succès."
        );
      }
    }
  };

  //Pourra être factorisée avec celle de ReceptionProductCard
  // const handleBlur = async (
  //   key: string,
  //   value: string,
  //   product: ReceptionLine,
  //   receiptLinesBis: { pagination: CommonPagination; items: ReceptionLine[] }
  // ) => {
  //   if (receiptLinesBis.items.length === 0) {
  //     const tempRes = await getLines(selectedReception);
  //     receiptLinesBis = {
  //       pagination: {
  //         pageNumber: tempRes.pageNumber,
  //         pageSize: tempRes.pageSize,
  //         pageCount: tempRes.pageCount,
  //         totalRows: tempRes.totalRows,
  //         rowsReturned: tempRes.rowsReturned,
  //       },
  //       items: tempRes.items,
  //     };
  //   }

  //   const orders = await getRelatedOrders(
  //     selectedReception,
  //     product.ean13,
  //     product.qteRecu
  //   );

  //   const resValo = await ReceptionApiClient.valoLine(
  //     selectedReception,
  //     {
  //       ...product,
  //       [key]: isNaN(parseFloat(value))
  //         ? 0
  //         : parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")), // Temp, there are better way to do that
  //     },
  //     key
  //   );

  //   const indexP = receiptLinesBis.items.findIndex(
  //     (p) => p.linePk === product.linePk
  //   );
  //   const tempTab = [...receiptLinesBis.items];
  //   const saveTab = [...receiptLinesBis.items];
  //   tempTab[indexP] = {
  //     ...product,
  //     [key]: isNaN(parseFloat(value))
  //       ? 0
  //       : parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")), // Temp, there are better way to do that
  //     tauxNet: resValo.tauxNet,
  //     prixNetHt: resValo.netHT,
  //   };
  //   setReceiptLines({ ...receiptLinesBis, items: tempTab });
  //   const res = await updateLine(
  //     {
  //       ...product,
  //       [key]: isNaN(parseFloat(value))
  //         ? 0
  //         : parseFloat(value.replace(",", ".").replace(/[^0-9.]/g, "")), // Temp, there are better way to do that
  //     },
  //     orders
  //   );
  //   if (!res) setReceiptLines({ ...receiptLinesBis, items: saveTab });

  //   // const lines = await getLines(selectedReception, {
  //   //   orderBy: "dateCreat",
  //   //   orderDir: "DESC",
  //   //   pageNumber: 1,
  //   //   pageSize: 80,
  //   // });
  //   // setReceiptLines({
  //   //   pagination: {
  //   //     pageNumber: lines.pageNumber,
  //   //     pageSize: lines.pageSize,
  //   //     pageCount: lines.pageCount,
  //   //     totalRows: lines.totalRows,
  //   //     rowsReturned: lines.rowsReturned + receiptLines.pagination.rowsReturned,
  //   //   },
  //   //   items: lines.items,
  //   // });

  //   //setTempItem(lines.items[0]);
  // };

  //Pourra être factorisée avec celle de ReceptionProductCard
  // const handleChange = async (
  //   key: string,
  //   value: string,
  //   product: ReceptionLine
  // ) => {
  //   if (product.linePk === selectedItem?.linePk) {
  //     setSelectedItem({ ...selectedItem, [key]: +value } as ReceptionLine);
  //   }

  //   valoLine(product, key, !value || isNaN(parseFloat(value)) ? "0" : value);

  //   //setTempItem({ ...selectedItem, [key]: +value } as ReceptionLine);
  // };

  const columns: Column<ReceptionLine>[] = [
    {
      title: "Articles",
      id: "article",
      render: (p, { responsive }) =>
        responsive ? (
          <div className="text-right" onClick={() => {}}>
            <Base
              className="cursor-text"
              data-tooltip={
                p.tooltip ? getCommonProductTooltip(p, p.tooltip) : p.desig
              }
            >
              {p.ean13}
            </Base>
            <br />
            <Info className="cursor-text">{p.ean13}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row grow xl:max-w-screen-2xl max-w-44 items-center space-x-4"
            onClick={() => {}}
          >
            {selectedReception && (
              <div
                key={`${selectedReception.numRecep}`}
                className="w-10 print:hidden"
              >
                <BookRender
                  src={p.imageURL}
                  productType={p.tooltip?.modeGest}
                />
              </div>
            )}
            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal w-full cursor-text"
                data-html={true}
                data-tooltip={
                  p.tooltip ? getCommonProductTooltip(p, p.tooltip) : p.desig
                }
              >
                {p.desig}
              </Base>
              <Info
                className="cursor-text"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                  const range = document.createRange();
                  range.selectNode(e.currentTarget);
                }}
              >
                {p.ean13}
              </Info>
            </div>
          </div>
        ),
    },
    {
      title: "Rayon",
      id: "rayon",
      render: (p) => <Base className="w-full text-start">{p.codeRayon}</Base>,
    },
    {
      title: "TVA",
      id: "tva",
      render: (p) => <Base className="w-full text-end">{p.codeTaxe}</Base>,
    },
    {
      title: `${selectedReception.modeGest === "L" ? "TTC" : "HT PU"} ${
        current?.devisSymb || "€"
      }`,
      id: "prixTtc",
      render: (p) => (
        <Base className="w-full text-end">
          {p.prixBase ? p.prixBase.toFixed(current?.devisNbDecim) : "--"}
          {current?.devisSymb}
        </Base>
      ),
    },
    {
      title: "% remise",
      id: "remise",
      render: (p) => <Base className="w-full text-end">{p.tauxNet}%</Base>,
    },
    {
      title: `HT PA ${current?.devisSymb || "€"}`,
      id: "prixBase",
      render: (p) => (
        <Base className="w-full text-end">
          {p.prixNetHt
            ? selectedReception.modeGest === "P"
              ? p.prixNetHt.toFixed(current?.devisNbDecimP)
              : p.prixNetHt.toFixed(current?.devisNbDecim)
            : "--"}
          {current?.devisSymb}
        </Base>
      ),
    },
    {
      title: "Quantité",
      id: "qteRecu",
      render: (p) => <Base className="w-full text-end">{p.qteRecu}</Base>,
    },
    {
      title: "Actions",
      id: "actions",
      render: (p) => (
        <div className="w-full flex gap-2 justify-end ">
          <ReceptionProductActions
            product={p}
            reception={selectedReception}
            disabled={true}
          />
        </div>
      ),
    },
  ];

  return (
    <Page loading={loading}>
      <div className="flex flex-col justify-start mx-1 sm:mx-6">
        <div className="grow flex flex-col mt-0 md:mt-2 md:flex-row md:justify-around">
          {!clotureStep ? (
            <div className="grow">
              <Title>
                {selectedReception.trait
                  ? "Réception clôturée"
                  : t("reception.Reception en cours")}
              </Title>
            </div>
          ) : (
            <div className="grow">
              <Title>Validation réception</Title>
            </div>
          )}
          {!clotureStep && (
            <div
              className={`grow flex flex-col-reverse gap-8 sm:gap-4 sm:flex-row items-center ${
                selectedReception.trait ? "justify-end" : ""
              }`}
            >
              {!selectedReception.trait && (
                <form
                  className="mx-auto w-full flex h-full w-full"
                  style={{ maxWidth: 540 }}
                  autoComplete="off"
                  onSubmit={async (e) => {
                    e.preventDefault();
                  }}
                >
                  <InputWithSuggestions
                    options={[]}
                    disabled={
                      functionsAuthorization[
                        `Recep-${selectedReception.codeTypeProd}`
                      ] &&
                      !functionsAuthorization[
                        `Recep-${selectedReception.codeTypeProd}`
                      ].update
                    }
                    id="scanner-input"
                    ean={true}
                    inputMode={scanOn ? "none" : undefined}
                    inputClassName="to-focus !rounded-l-lg !rounded-r-none"
                    autoFocus={true}
                    placeholder="Scanner"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.currentTarget.value)}
                  />

                  <Button
                    className="shrink-0 !rounded-r-lg !rounded-l-none"
                    authorized={
                      functionsAuthorization[
                        `Recep-${selectedReception.codeTypeProd}`
                      ] &&
                      functionsAuthorization[
                        `Recep-${selectedReception.codeTypeProd}`
                      ].update
                    }
                    shortcut={
                      affectationModal.open || printLabelModal.open
                        ? []
                        : ["enter"]
                    }
                    icon={(p) => <PlusIcon {...p} />}
                    onClick={async () => {
                      if (inputValue.trim() === "" && selectedItem)
                        await handleValidateLigne();
                      else {
                        await handleSubmit(inputValue);
                      }
                    }}
                  />
                </form>
              )}

              <div className="flex gap-4 my-y md:mt-0 items-center mt-4">
                <Button
                  theme="danger-outlined"
                  onClick={() => {
                    navigate(ROUTES.Receptions);
                    setTab({ ...tab, header: false });
                  }}
                  icon={(p) => <ArrowNarrowLeftIcon {...p} />}
                >
                  Retour
                </Button>

                <Button
                  disabled={selectedReception.qteRecu === 0}
                  theme="primary"
                  onClick={async () => {
                    setClotureStep(true);
                    window.history.pushState(
                      {},
                      "",
                      ROUTES.OngoingReception.replace(
                        /:numRecep/,
                        selectedReception.numRecep
                      ).replace(/:clotStep/, "1")
                    );
                    setTab({ ...tab, header: true });
                  }}
                  icon={(p) => <CheckCircleIcon {...p} />}
                >
                  {!selectedReception.trait
                    ? "Clôturer"
                    : "Voir infos de clôtures"}
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col mt-4 justify-start h-full w-full md:mt-6 space-y-6 md:space-y-0 gap-1">
          {!clotureStep && selectedItem && (
            <div className="flex flex gap-2">
              <Accordion
                parentClassName="w-full"
                onChange={(newTabValue) => {
                  setTab(newTabValue);
                }}
                value={tab}
                panels={[
                  {
                    value: "selectedItem",
                    label: "Produit",
                    node: (
                      <div
                        className="flex flex-col sm:flex-row justify-start
                     w-full gap-4"
                      >
                        <div className="flex flex-col sm:w-1/4 gap-2">
                          {!selectedItem.forceRelatedOrders && (
                            <div className="flex w-full border-2 p-2 gap-2 md:gap-4 justify-start items-center justify-center">
                              <InfoSmall>Étiqueter</InfoSmall>
                              <Button
                                className="grow max-w-6"
                                size="sm"
                                disabled={true}
                                theme="primary"
                                icon={({ className }) => (
                                  <TagIcon className={className} />
                                )}
                                onClick={async () => {
                                  await ReceptionApiClient.printLabels(
                                    selectedReception,
                                    selectedItem,
                                    selectedItem.nbrEtiq
                                  );
                                }}
                              />
                              {selectedItem.qteCdeCli === 0 && (
                                <div style={{ maxWidth: 160, minWidth: 120 }}>
                                  <InputCounter
                                    id={"etiq-counter"}
                                    size="sm"
                                    value={selectedItem.nbrEtiq}
                                    min={0}
                                    onChange={(newValue) => {
                                      setSelectedItem({
                                        ...selectedItem,
                                        nbrEtiq: newValue,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          <Frame className="relative w-full h-full bg-slate-300 flex flex-col gap-2">
                            <div
                              data-tooltip={
                                updateMode
                                  ? "Modification d'une ligne existente"
                                  : "Création d'une nouvelle ligne"
                              }
                              data-position={"right"}
                              className={`absolute top-0 left-0 h-5 w-5 opacity-70 flex flex-col justify-center rounded-tl-md rounded-br-md ${
                                updateMode ? "bg-teal-500" : "bg-green-500"
                              } text-center`}
                            >
                              <InfoSmallBold className="w-full text-center">
                                {updateMode ? "U" : "I"}
                              </InfoSmallBold>
                            </div>
                            <div className="select-text">
                              <ReceptionProductCard
                                reception={selectedReception}
                                product={selectedItem}
                                autoFocusQuantity={true}
                                lock={lock}
                              />
                            </div>
                          </Frame>
                          <div className="flex w-full justify-center gap-8">
                            <Button
                              theme="danger"
                              onClick={() => {
                                setTabKey((previous) => {
                                  return { ...previous, refresh: false };
                                });
                                setSelectedItem(null);
                                setUpdateMode(false);
                              }}
                            >
                              Annuler
                            </Button>
                            <Button
                              //Will need factorisation later on
                              onClick={async () => await handleValidateLigne()}
                            >
                              Valider la ligne
                            </Button>
                          </div>
                        </div>
                        <div className="grow mt-2 flex flex-col gap-2">
                          <div className="overflow-visible">
                            <Form
                              onChange={(newValue) => {
                                if (Array.isArray(newValue.rayonObj)) {
                                  if (newValue.rayonObj[0])
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      rayonObj: newValue.rayonObj[0],
                                      libRayon: newValue.rayonObj[0].label,
                                      codeRayon: newValue.rayonObj[0].value,
                                    });
                                  else {
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      rayonObj: undefined,
                                      libRayon: "",
                                      codeRayon: "",
                                    });
                                  }
                                }
                                if (Array.isArray(newValue.fabObj)) {
                                  if (newValue.fabObj[0])
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      fabObj: newValue.fabObj[0],
                                      editFab: newValue.fabObj[0].label,
                                      codeEditFab: newValue.fabObj[0].value,
                                    });
                                  else {
                                    setSelectedItem({
                                      ...(newValue as ReceptionLine),
                                      fabObj: undefined,
                                      editFab: "",
                                      codeEditFab: "",
                                    });
                                  }
                                }
                              }}
                              value={selectedItem}
                              fields={[
                                {
                                  key: "rayonObj",
                                  label: t("Rayon"),
                                  type: "searchselect",
                                  alwaysVisible: true,
                                  options: async (query) => {
                                    const result = await getSuggestions({
                                      Rayon: query,
                                      ModeGestion: selectedReception.modeGest,
                                    });
                                    return result.map((el) => {
                                      return {
                                        label: el.label,
                                        value: el.value,
                                      };
                                    });
                                  },
                                  edit: true,
                                },
                                {
                                  key: "fabObj",
                                  label:
                                    selectedItem.tooltip?.modeGest !== "P"
                                      ? "Éditeur"
                                      : "Fabricant",
                                  type: "searchselect",
                                  alwaysVisible: true,
                                  options: async (query) => {
                                    if (query.length > 3) {
                                      const result =
                                        await ProductsApiClient.getBookEditor({
                                          Editeur: query,
                                          CodeEdit: query,
                                        });
                                      return result;
                                    }
                                  },
                                  edit: true,
                                },
                              ]}
                            />
                          </div>
                          <div className="w-full">
                            <Form
                              onChange={() => {}}
                              value={selectedItem}
                              readonly={true}
                              fieldStyle={{ minWidth: 86, maxWidth: 128 }}
                              fields={[
                                {
                                  key: "prixVenteRef",
                                  label: t("Prix vente"),
                                  type: "formatted",
                                  format: "price",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "margeAchat",
                                  label: t("Marge achat"),
                                  type: "formatted",
                                  format: "percentage",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "margeVente",
                                  label: t("Marge vente"),
                                  type: "formatted",
                                  format: "percentage",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "qteStock",
                                  label: t("Quantite stock"),
                                  type: "number",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "dateDernRecep",
                                  label: t("Date derniere reception"),
                                  type: "date",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "prixPromo",
                                  label: t("Prix promo"),
                                  type: "formatted",
                                  format: "price",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "dateDebut",
                                  label: t("Date debut"),
                                  type: "date",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "dateFin",
                                  label: t("Date fin"),
                                  type: "date",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "codeCoef",
                                  label: t("Coef"),
                                  type: "number",
                                  alwaysVisible: true,
                                },
                                {
                                  key: "codeCondi",
                                  label: t("Conditionnement"),
                                  type: "number",
                                  alwaysVisible: true,
                                },

                                {
                                  key: "codePanie", //N'éxiste pas encore
                                  label: t("Paniere"),
                                  type: "text",
                                  alwaysVisible: true,
                                },

                                {
                                  key: "refer", //N'éxiste pas encore
                                  label: t("Reference"),
                                  type: "text",
                                  alwaysVisible: true,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          )}

          <div className="grow overflow-hidden flex flex-col gap-2">
            <Accordion
              onChange={(newTabValue) => {
                setTab(newTabValue);
              }}
              value={tab}
              panels={[
                {
                  value: "header",
                  label: (
                    <div className="flex flex-row gap-2">
                      <div className="flex flex-col justify-center items-end w-6 h-6">
                        <BookRender
                          src=""
                          productType={selectedReception.modeGest || "P"}
                        />
                      </div>
                      <Base
                        onDoubleClick={(e) => e.stopPropagation()}
                        className="select-text"
                      >{`${selectedReception.codeFourn} ${
                        selectedReception.numRecep !== ""
                          ? "- Reception " + selectedReception.numRecep
                          : ""
                      } - BL ${selectedReception.numBL} - Bordereau ${
                        selectedReception.bord !== ""
                          ? selectedReception.bord
                          : selectedReception.numBL
                      } `}</Base>
                    </div>
                  ),

                  node: (
                    <div className="grow flex flex-col gap-4">
                      {selectedReception.typeRecep === 0 &&
                      selectedReception.nbrColisRecep ? (
                        <div className="w-full flex gap-2">
                          <BaseBold>
                            État d'avancement du bon de livraison:
                          </BaseBold>
                          <Base>
                            {`${selectedReception.nbrColisRecep} / ${selectedReception.nbrColis} colis réceptionnés ou en cours de traitement.`}
                          </Base>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Frame className="mb-2">
                        <div className="flex flex-row gap-2 justify-between items-center mb-4">
                          {clotureStep && (
                            <div className="flex gap-6">
                              <Button
                                theme="secondary"
                                onClick={async () => {
                                  const res = await getReceiptDocument("recep");
                                  if (res)
                                    setPdfModal({
                                      open: true,
                                      pdfProps: {
                                        blobFile: res,
                                        iframeSize: {
                                          width: "80%",
                                          height: "90%",
                                        },
                                        iFrameClassname:
                                          "w-full h-full flex justify-center items-center",
                                      },
                                    });
                                }}
                                icon={(p) => <PrinterIcon {...p} />}
                              >
                                {finishedStep || selectedReception.trait
                                  ? "Imprimer bon de réception"
                                  : "Imprimer brouillard"}
                              </Button>
                              {selectedReception.typeRecep !== 1 && (
                                <Button
                                  theme="secondary"
                                  onClick={async () =>
                                    setMissingArticlesModal({
                                      open: true,
                                      missingArticles:
                                        await getMissingArticles(), //Temp data, just for debugging purpose
                                      motifs:
                                        await CommonApiClient.getAvailibility(
                                          selectedReception.codeTypeProd
                                        ),
                                    })
                                  }
                                  icon={(p) => <FaArrowRightArrowLeft {...p} />}
                                >
                                  Gérer les manquants
                                </Button>
                              )}
                              {selectedReception.typeRecep === 0 && (
                                <Button
                                  theme="secondary"
                                  onClick={async () => {
                                    const res = await getReceiptDocument(
                                      "ecart"
                                    );
                                    if (res) {
                                      if (res.size === 0) {
                                        toast.success(
                                          "Votre réception ne contient aucun écart"
                                        );
                                      } else {
                                        setPdfModal({
                                          open: true,
                                          pdfProps: {
                                            blobFile: res,
                                            iframeSize: {
                                              width: "80%",
                                              height: "90%",
                                            },
                                            iFrameClassname:
                                              "w-full h-full flex justify-center items-center",
                                          },
                                        });
                                      }
                                    }
                                  }}
                                  icon={(p) => <PaperClipIcon {...p} />} //Je savais pas quel icône choisir
                                >
                                  {t("Imprimer écarts")}
                                </Button>
                              )}
                            </div>
                          )}
                        </div>

                        <Form
                          minColumns={2}
                          readonly={true}
                          onChange={() => ""}
                          value={selectedReception}
                          fields={[
                            {
                              key: "typeRecepLibel",
                              label: "Type de réception",
                              type: "text",
                              alwaysVisible: true,
                            },
                            {
                              key: "codeTypeTaux",
                              label: t("reception.tauxImplicite"),
                              type: "text",
                              alwaysVisible: true,
                            },
                            {
                              key: "codeTypeAchat",
                              label: t("reception.typeAchat"),
                              type: "select",
                              alwaysVisible: true,
                              options: [
                                {
                                  label: "Achat ferme",
                                  value: "0",
                                },
                                {
                                  label: "Office",
                                  value: "1",
                                },
                                {
                                  label: "Dépôt permanent",
                                  value: "2",
                                },
                                {
                                  label: "Dépôt occasionnel",
                                  value: "3",
                                },
                                {
                                  label: "Droit de retour",
                                  value: "4",
                                },
                              ],
                            },
                            {
                              key: "recepAffecCdeCli",
                              label: "Affecter commandes clients",
                              type: "select",
                              alwaysVisible: true,
                              options: [
                                { label: "Non", value: "0" },
                                {
                                  label: "Oui",
                                  value: "1",
                                },
                                {
                                  label: "Oui par défaut",
                                  value: "2",
                                },
                                {
                                  label: "Non par défaut",
                                  value: "3",
                                },
                              ],
                            },
                            {
                              key: "modeAffecCdeCli",
                              label: t("reception.Mode affectation"),
                              type: "select",
                              alwaysVisible: true,
                              options: [
                                {
                                  label: "Par ancienneté cde cli",
                                  value: "0",
                                },
                                {
                                  label: "Par ancienneté cde cli",
                                  value: "",
                                },
                                {
                                  label: "Priorité aux cde cli associées",
                                  value: "PRIO",
                                },
                                {
                                  label: "Exclusivement cde cli associées",
                                  value: "EXCL",
                                },
                              ],
                            },
                            {
                              key: "etiqCdeCli",
                              label: "Étiqueter les commandes",
                              type: "select",
                              options: [
                                { label: "Étiquetage habituel", value: "0" },
                                {
                                  label:
                                    "Étiqueter toutes les commandes client",
                                  value: "1",
                                },
                                {
                                  label:
                                    "Ne pas étiqueter les commandes client",
                                  value: "2",
                                },
                              ],
                              alwaysVisible: true,
                            },
                            {
                              key: "dateMiniAvantRet",
                              label: t("Retour des le"),
                              type: "date",
                              alwaysVisible: true,
                            },
                            {
                              key: "dateMaxiRet",
                              label: t("retourJusquau"),
                              type: "date",
                              alwaysVisible: true,
                            },
                          ]}
                        />
                        <div className="flex flex-col mt-1 gap-1">
                          <Info>Commentaire interne</Info>
                          <Input
                            className="h-10"
                            multiline={true}
                            readOnly={true}
                            disabled={true}
                            value={selectedReception.comm}
                            onChange={(e) =>
                              changeSelectedReception({
                                ...selectedReception,
                                comm: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Frame>
                    </div>
                  ),
                },
              ]}
            />
            {!clotureStep && selectedReception.qteRecu === 0 && (
              <div className="w-full flex justify-end gap-2">
                {(selectedReception.typeRecep === 2 ||
                  selectedReception.typeRecep === 0) &&
                  receiptLines.items.length === 0 && (
                    <Button
                      size="md"
                      authorized={
                        functionsAuthorization[
                          `Recep-${selectedReception.codeTypeProd}`
                        ] &&
                        functionsAuthorization[
                          `Recep-${selectedReception.codeTypeProd}`
                        ].update
                      }
                      theme="secondary"
                      className="shrink-0"
                      disabled={loading}
                      icon={({ className }) => (
                        <FaArrowsRotate className={className} />
                      )}
                      onClick={async () => {
                        const resRappat = await rappatrierLines({
                          ...selectedReception,
                          numCde:
                            selectedReception.typeRecep === 2 &&
                            selectedReception.listReceiptSupplierOrder
                              ? selectedReception.listReceiptSupplierOrder[0]
                                  .numCde
                              : "",
                        });
                        if (!resRappat) {
                          toast.error("Impossible de rapatrier les lignes.");
                          return;
                        }
                        const lines = await getLines(selectedReception, {
                          orderBy: "dateCreat",
                          orderDir: "DESC",
                          pageNumber: 1,
                          pageSize: 20,
                        });
                        setReceiptLines({
                          pagination: {
                            pageNumber: lines.pageNumber,
                            pageSize: lines.pageSize,
                            pageCount: lines.pageCount,
                            totalRows: lines.totalRows,
                            rowsReturned:
                              lines.rowsReturned +
                              receiptLines.pagination.rowsReturned,
                          },
                          items: lines.items,
                        });
                        if (
                          selectedReception.etiqCdeCli !== 2 &&
                          selectedReception.recepAffecCdeCli !== "2" &&
                          selectedReception.recepAffecCdeCli !== "3"
                        ) {
                          await printLabels(
                            selectedReception,
                            undefined,
                            undefined,
                            undefined,
                            true
                          );
                        }
                        const allLines = (await getLines(
                          selectedReception,
                          {
                            orderDir: undefined,
                            pageNumber: 0,
                            pageSize: 9999,
                          },
                          true
                        )) as {
                          pagination: CommonPagination;
                          items: ReceptionLine[];
                        };
                        const kits = allLines.items.filter((el) => el.kit);
                        if (resRappat.length !== 0) {
                          setUnknownArticlesModal({
                            open: true,
                            unknownArticles: resRappat,
                            kits: kits,
                          });
                        }
                        if (resRappat.length === 0 && kits.length > 0) {
                          setEclatKitModal({
                            open: true,
                            receipt: selectedReception,
                            products: kits,
                            closeOnValid: false,
                          });
                        }

                        if (
                          selectedReception.recepAffecCdeCli === "2" ||
                          selectedReception.recepAffecCdeCli === "3"
                        ) {
                          const filteredLines = allLines.items.filter(
                            (l) => l.qteCdeCli > 0
                          );

                          if (filteredLines.length > 0) {
                            const orders = await getRelatedOrders(
                              selectedReception,
                              filteredLines[0].ean13,
                              filteredLines[0].linePk,
                              filteredLines[0].qteRecu,
                              true
                            );
                            setAffectationModal({
                              open: true,
                              receipt: selectedReception,
                              item: allLines.items[0] as ReceptionLine,
                              orders: orders,
                              total: 0,
                              automaticMode: true,
                              itemList: filteredLines,
                              autoPrint: !updateMode,
                            });
                          }
                        }
                      }}
                    >
                      Rapatrier les lignes
                    </Button>
                  )}
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setConfirmModal({
                      theme: "danger",
                      icon: () => (
                        <FaExclamationTriangle className="text-red-600 h-6 w-6" />
                      ),
                      open: true,
                      title: "Supprimer la réception",
                      message: "Veuillez confirmer la suppression",
                      onAccept: async () => {
                        if (selectedReception.qteRecu > 0)
                          toast.error(
                            "Cette réception ne peut être supprimée car une ou plusieurs ligne(s) ont déja été réceptionnée(s)"
                          );
                        else {
                          await deleteReceipt(selectedReception);
                        }
                      },
                    });
                  }}
                  theme="danger"
                  authorized={
                    functionsAuthorization[
                      `Recep-${selectedReception.codeTypeProd}`
                    ].delete
                  }
                  icon={({ className }) => <XIcon className={className} />}
                  data-tooltip="Supprimer la réception"
                >
                  Supprimer la réception
                </Button>
              </div>
            )}{" "}
            {!clotureStep &&
              (selectedReception.modeGest === "L" ||
                selectedReception.listReceiptSupplierOrder) && (
                <Frame>
                  <div className="flex flex-row w-full max-w-screen-lg gap-8">
                    {selectedReception.typeRecep !== 1 &&
                      selectedReception.listReceiptSupplierPackage && (
                        <div className="flex flex-row justify-starts w-full items-center">
                          <Info className="text-center ">Afficher colis: </Info>
                          <Select
                            value={selectedReception.numColis}
                            onChange={async (e) => {
                              changeSelectedReception({
                                ...selectedReception,
                                numColis: e.target.value,
                              });
                              const lines = await getLines(
                                {
                                  ...selectedReception,
                                  numColis: e.target.value,
                                },
                                {
                                  orderBy: "dateCreat",
                                  orderDir: "DESC",
                                  pageNumber: 0,
                                  pageSize: 20,
                                }
                              );
                              if (lines) {
                                setReceiptLines({
                                  pagination: {
                                    pageNumber: lines.pageNumber,
                                    pageSize: lines.pageSize,
                                    pageCount: lines.pageCount,
                                    totalRows: lines.totalRows,
                                    rowsReturned: lines.rowsReturned,
                                  },
                                  items: lines.items,
                                });
                              }
                            }}
                            size={"md"}
                            placeholder={""}
                          >
                            {[
                              {
                                numColis: "-1",
                                idColis: "",
                                livrNbRef: -1,
                                recepNbRef: -1,
                                livrNb:
                                  selectedReception.listReceiptSupplierPackage.reduce(
                                    (sum, el) =>
                                      (sum = {
                                        ...sum,
                                        livrNb: sum.livrNb + el.livrNb,
                                      })
                                  ).livrNb | 0,
                                recepNb:
                                  selectedReception.listReceiptSupplierPackage.reduce(
                                    (sum, el) =>
                                      (sum = {
                                        ...sum,
                                        recepNb: sum.recepNb + el.recepNb,
                                      })
                                  ).recepNb,
                              },
                            ]
                              .concat(
                                selectedReception.listReceiptSupplierPackage
                              )
                              .map((el, index) => (
                                <option key={index} value={el.numColis}>
                                  {el.numColis !== "-1"
                                    ? `Numéro colis: ${el.numColis} - ${el.idColis} - ${el.recepNbRef}/${el.livrNbRef} références - ${el.recepNb}/${el.livrNb} articles`
                                    : `Tous - ${el.recepNb}/${el.livrNb} articles`}
                                </option>
                              ))}
                          </Select>
                        </div>
                      )}

                    {receiptLines.items.some(
                      (line) => line.tooltip?.modeGest === "L"
                    ) &&
                      receiptLines.items.length > 0 && (
                        <ButtonConfirm
                          confirmTitle="Mettre à jour les prix des fiches articles"
                          confirmMessage={`Vous êtes sur le point de modifier les fiches articles des produits actuellement présents dans cette réception. Êtes-vous sûr de vouloir continuer ?`}
                          className="shrink-0"
                          size="md"
                          onClick={async (e) => {
                            e.stopPropagation();
                            await updateAllPrices();
                          }}
                          theme="secondary"
                          icon={({ className }) => (
                            <RefreshIcon className={className} />
                          )}
                        >
                          MAJ prix vente
                        </ButtonConfirm>
                      )}

                    {selectedReception.listReceiptSupplierOrder && (
                      <Button
                        className="shrink-0"
                        size="md"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCommandFournModal({
                            open: true,
                            listCde: selectedReception.listReceiptSupplierOrder,
                          });
                        }}
                        theme="secondary"
                        icon={({ className }) => (
                          <ViewListIcon className={className} />
                        )}
                      >
                        {selectedReception.listReceiptSupplierOrder.length > 1
                          ? `Commande fournisseurs associées (${selectedReception.listReceiptSupplierOrder.length})`
                          : `Commande ${selectedReception.listReceiptSupplierOrder[0].numCde} associée`}
                      </Button>
                    )}
                  </div>
                </Frame>
              )}
            {!clotureStep && (
              <div>
                <TableGridSwitch
                  name={`ongoingLine${selectedReception.numBL}`}
                  //codePres="rcpLines"
                  //key={`${tabKey}-${selectedItem?.linePk}`}
                  key={`${tabKey}`}
                  gridMode={gridMode.activated}
                  onChangeMode={(v) => setGridMode({ activated: v })}
                  onClick={async (el) => {
                    if (!window.getSelection()?.toString()) {
                      if (selectedItem && selectedItem.linePk !== el.linePk) {
                        if (selectedItem.qteCdeCli > 0) {
                          const orders = await getRelatedOrders(
                            selectedReception,
                            selectedItem.ean13,
                            selectedItem.linePk,
                            selectedItem.qteRecu
                          );
                          setAffectationModal({
                            open: true,
                            receipt: selectedReception,
                            item: selectedItem,
                            orders: orders,
                            queingItem: el,
                            autoPrint: !updateMode,
                          });
                          return;
                        }
                      }

                      const relatedOrders = await getRelatedOrders(
                        selectedReception,
                        el.ean13,
                        el.linePk,
                        el.qteRecu
                      );
                      setAffectationModal((prev) => ({
                        ...prev,
                        orders: relatedOrders,
                      }));
                      setSelectedItem({
                        ...el,
                        nbrEtiq: el.nbrEtiq,
                        rayonObj: {
                          label: el.libRayon,
                          value: el.codeRayon,
                        },
                        fabObj: {
                          label: el.editFab,
                          value: el.codeEditFab,
                        },
                        forceRelatedOrders: relatedOrders.length > 1,
                      });
                      setTabKey((previous) => {
                        return { ...previous, refresh: false };
                      });
                      setTab({ selectedItem: true });
                      setTimeout(() => {
                        focusInput(
                          selectedReception.typeRecep === 1
                            ? "#product-counter input"
                            : "#etiq-counter"
                        );
                      }, 250);
                      setUpdateMode(true);
                    }
                  }}
                  renderGrid={(p) => (
                    <ReceptionProductCard
                      disabled={true}
                      product={p}
                      reception={selectedReception}
                      showBorderOnSelected={true}
                      lock={lock}
                      disableOnselected={true}
                    />
                  )}
                  tableColumns={columns}
                  data={receiptLines.items}
                  emptyTabText="Aucune ligne de réception"
                  showPagination={
                    loading || receiptLines.items.length === 0 ? false : "full"
                  }
                  onFetchExportData={async () => {
                    return receiptLines.items;
                  }}
                  total={receiptLines.pagination.totalRows}
                  exportDefaultHeader={[
                    "desig",
                    "ean13",
                    "prixBase",
                    "tauxNet",
                    "prixNetHt",
                    "qteRecu",
                    "libRayon",
                    "codeFourn",
                  ]}
                  exportUniqKey="linePk"
                  initialPagination={{
                    perPage: 15,
                    page: 1,
                    orderBy: 0,
                    order: "DESC",
                  }}
                  onRequestData={async ({ page, perPage, order, orderBy }) => {
                    if (tabKey.refresh) {
                      const lines = await getLines(selectedReception, {
                        orderBy: "dateCreat",
                        orderDir: order || "DESC",
                        pageNumber: page,
                        pageSize: 20,
                      });
                      setReceiptLines({
                        pagination: {
                          pageNumber: lines.pageNumber,
                          pageSize: lines.pageSize,
                          pageCount: lines.pageCount,
                          totalRows: lines.totalRows,
                          rowsReturned:
                            lines.rowsReturned +
                            receiptLines.pagination.rowsReturned,
                        },
                        items: lines.items,
                      });
                    }
                    setTabKey((previous) => {
                      return { ...previous, refresh: true };
                    });
                  }}
                  gridClassName={
                    "grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
                  }
                />
              </div>
            )}
            {clotureStep && (
              <div key={`${clotureStep}`} className="flex flex-col gap-2">
                <Frame className="w-full ">
                  <Form
                    key={`${clotureStep}`}
                    minColumns={2}
                    readonly={true}
                    fields={receptionInfoModalFields}
                    value={selectedReception}
                    onChange={() => {}}
                  />
                </Frame>
                <div className="w-full flex-auto mt-2 ">
                  <Form
                    readonly={false}
                    key={`${tvaList.length}`}
                    minColumns={3}
                    fields={[
                      {
                        key: "fraisPortHt",
                        label: "Frais de port HT",
                        type: "formatted",
                        format: "price",
                        edit: true,
                      },
                      {
                        key: "codeTaxe",
                        label: "TVA",
                        type: "select",
                        options: tvaList,
                        edit: true,
                      },
                      {
                        key: "fraisPortTtc",
                        label: "Frais de port TTC",
                        type: "formatted",
                        format: "price",
                        edit: true,
                      },
                    ]}
                    value={selectedReception}
                    onChange={async (newValue) => {
                      changeSelectedReception(newValue as ReceptionUnit);
                      const orig =
                        selectedReception.codeTaxe !== newValue.codeTaxe
                          ? "code_taxe"
                          : selectedReception.fraisPortHt !==
                            newValue.fraisPortHt
                          ? "frais_port_ht"
                          : "frais_port_ttc";
                      const res =
                        await CommonApiClient.getAmountCalculationByTaxe(
                          orig,
                          newValue.codeTaxe,
                          newValue.fraisPortHt,
                          newValue.fraisPortTtc
                        );
                      if (res)
                        changeSelectedReception({
                          ...selectedReception,
                          codeTaxe: newValue.codeTaxe,
                          fraisPortHt: res.ht,
                          fraisPortTtc: res.ttc,
                        });
                      else {
                      }
                    }}
                  />
                </div>

                <div className="flex grow justify-evenly mt-4">
                  {" "}
                  {!finishedStep && (
                    <Button
                      theme="danger"
                      onClick={() => {
                        setClotureStep(false);
                        window.history.pushState(
                          {},
                          "",
                          ROUTES.OngoingReception.replace(
                            /:numRecep/,
                            selectedReception.numRecep
                          ).replace(/:clotStep/, "0")
                        );
                        setTab({ ...tab, header: false });
                      }}
                    >
                      {selectedReception.trait
                        ? "Retour liste lignes"
                        : "Annuler"}
                    </Button>
                  )}
                  {!selectedReception.trait && (
                    <Button
                      disabled={loading}
                      onClick={async (e) => {
                        if (!finishedStep) {
                          const allLines = (await getLines(
                            selectedReception,
                            {
                              orderDir: undefined,
                              pageNumber: 0,
                              pageSize: 9999,
                            },
                            true
                          )) as {
                            pagination: CommonPagination;
                            items: ReceptionLine[];
                          };

                          const onlyKit = allLines.items.filter((el) => el.kit);
                          if (onlyKit.length > 0) {
                            setEclatKitModal({
                              open: true,
                              receipt: selectedReception,
                              products: onlyKit,
                              closeOnValid: true,
                            });
                          } else {
                            const closeEvent = new CustomEvent(
                              "toCloseReceipt"
                            );

                            window.dispatchEvent(closeEvent);

                            // const res = await closeReceipt();
                            // if (res) {
                            //   setFinishedStep(true);
                            //   toast.success("Réception clôturée avec succès");
                            // }
                          }
                        } else navigate(ROUTES.Receptions);
                      }}
                    >
                      {finishedStep
                        ? "Retourner à l'accueil de la réception"
                        : "Valider"}
                    </Button>
                  )}
                  {selectedReception.trait && (
                    <Button
                      disabled={
                        loading ||
                        (initialTva.codeTaxe === selectedReception.codeTaxe &&
                          initialTva.fraisPortHt ===
                            selectedReception.fraisPortHt &&
                          initialTva.fraisPortTtc ===
                            selectedReception.fraisPortTtc)
                      }
                      onClick={async (e) => {
                        const res = await updateFdp(selectedReception);
                        if (res) toast.success("Frais de ports mis à jour");
                        else
                          toast.error(
                            "Erreur dans la mise à jour des frais de port"
                          );
                      }}
                    >
                      Valider modifications
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
